import Chart, { useChart } from 'src/components/chart';
import { useTheme } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import { t } from 'i18next';
import { useCurrentWorkspace } from 'src/context/reducers/app-settings';
import { fPercent } from 'src/utils/format-number';

type Props = {
  series: {
    name: string;
    type: string;
    data: number[];
  }[];
  labels: string[];
};

export default function ChartMixed({ series, labels }: Props) {
  const theme = useTheme();
  const filterStore = useSelector((state: any) => state.filters);
  const workspace = useCurrentWorkspace();

  enum ColorSchema {
    PRIMARY = 'primary',
    SECONDARY = 'secondary',
    TERTIARY = 'tertiary',
    ERROR = 'error',
    WARNING = 'warning',
    INFO = 'info',
    SUCCESS = 'success'
  }

  const formatCurrency = (number: any, options: any, isAdCost = false) => {
    const lang: any = localStorage.getItem('i18nextLng');
    const currencySymbol = workspace?.currency === "usd" ? '$' : '€';

    let maximumFractionDigits = 2;
    if (number >= 1000000) {
      maximumFractionDigits = 1;
    } else if (number >= 10000) {
      maximumFractionDigits = 1;
    } else if (number < 1) {
      maximumFractionDigits = 4;
    }

    options = { ...options, maximumFractionDigits };

    let formattedNumber;
    if (isAdCost) {
      if (number >= 1000000) {
        formattedNumber = (number / 1000000).toLocaleString(lang, { maximumFractionDigits }) + 'M';
      } else if (number >= 10000) {
        formattedNumber = (number / 1000).toLocaleString(lang, { maximumFractionDigits }) + 'K';
      } else {
        formattedNumber = number.toLocaleString(lang, options);
      }
    } else if (number >= 1000000) {
      formattedNumber = (number / 1000000).toLocaleString(lang, { maximumFractionDigits }) + 'M';
    } else if (number >= 10000) {
      formattedNumber = (number / 1000).toLocaleString(lang, { maximumFractionDigits }) + 'K';
    } else {
      formattedNumber = number.toLocaleString(lang, options);
    }

    if (lang === 'fr') {
      formattedNumber = formattedNumber.replace('.', ',');
    }

    return currencySymbol + formattedNumber;
  };

   // Fonction pour limiter les valeurs à 100 %
   const limitTo100 = (value: number) => Math.min(value, 100);

   // Vérifie si la série est de type pourcentage
   const isPercentageData = (name: string) => {
     return name.includes("CTR") || name.includes("vulnerability"); // Ajustez selon vos besoins
   };
 
   // Limiter les valeurs des séries à 100 % uniquement pour les pourcentages
   const adjustedSeries = series.map((serie) => ({
     ...serie,
     data: serie.data.map((value) => isPercentageData(serie.name) ? limitTo100(value) : value),
   }));

  const chartOptions = useChart({
    plotOptions: {
      bar: { columnWidth: '50%', borderRadius: 0 },
    },
    chart: {
      stacked: true,
    },
    fill: {
      colors: adjustedSeries?.length === 2
        ? ['#ff9800', '#5119b7']
        : ['#5119b7', '#ffb300', '#ffd54f', '#ffe180']
    },
    colors: adjustedSeries?.length === 2
      ? ['#ff9800', '#5119b7']
      : ['#5119b7', '#ffb300', '#ffd54f', '#ffe180'],
    labels: labels || [],
    markers: {
      size: 6
    },
    xaxis: {},
    yaxis: adjustedSeries?.length === 2 ? [
      {
        title: { text: t('dashboard.global.ad_cost') },
        labels: { formatter(value) { return (value / 1000).toFixed(0) + "k" } }
      },
      {
        title: { text: t('dashboard.global.blended_ctr') },
        opposite: true,
        min: 0,
        labels: { formatter(value) { return Math.min(value, 100).toFixed(0) + "%" } } // Empêche l'affichage au-dessus de 100 %
      }
    ]
      : [
        {
          title: { text: t('dashboard.global.blended_ctr') },
          opposite: true,
          labels: { formatter(value) { return Math.min(value, 100).toFixed(0) + "%" } }, // Empêche l'affichage au-dessus de 100 %
          min: 0
        },
        ...(adjustedSeries.some(serie => serie.name === t('dashboard.brandTraffic.searchAdCost') || serie.name === t('dashboard.brandTraffic.shoppingAdCost')) ? [{
          title: { text: t('dashboard.brandTraffic.searchAdCost') + '/' + t('dashboard.brandTraffic.shoppingAdCost') },
          labels: { formatter(value: any) { return formatCurrency(value, { minimumFractionDigits: 1, maximumFractionDigits: 1 }) } }
        }] : [])
      ],
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (value: number, { seriesIndex, w }) => {
          if (typeof value !== 'undefined') {
            const seriesType = w.config.series[seriesIndex].type;
            if (seriesType === "line") {
              return fPercent(Math.min(value, 100)); // Empêche l'affichage de plus de 100 %
            } else {
              return formatCurrency(value, { minimumFractionDigits: 1, maximumFractionDigits: 1 });
            }
          }
          return value;
        },
      },
    },
    annotations:{
      xaxis: workspace?.name === "Demo" ? [
    {
      x: filterStore?.timestep === 'date' ? '10/01/2023' : filterStore?.timestep === 'year_week' ? '09/18/2023' : '',
      x2: filterStore?.timestep === 'date' ? '10/16/2023' : filterStore?.timestep === 'year_week' ? '10/02/2023' : '',
      fillColor: '#ffe5e9',
      
    },
        {
      x: filterStore?.timestep === 'date' ? '10/08/2023' : filterStore?.timestep === 'year_week' ? '09/25/2023': '',
      fillColor: '#ffe5e9',
      borderColor: 'transparent',
      label: {
            orientation: "horizontal",
            borderColor: "#FF4560",
            style: {
              color: "#fff",
              background: "#FF4560",
              fontSize:'16px',
            },
            text: "Before Cross Brand"
          }
    },
         {
          // in a datetime series, the x value should be a timestamp, just like it is generated below
          x:filterStore?.timestep === 'date' ? '10/16/2023' : filterStore?.timestep === 'year_week' ? '10/02/2023' : '',
          strokeDashArray: 0,
          borderColor: "#775DD0",
          label: {
            borderColor: "#775DD0",
            style: {
              color: "#fff",
              background: "#775DD0",
              fontSize:'14px',
            },
            text: "Cross Brand Deployment"
          }
        },
        {
      x:filterStore?.timestep === 'date' ? '10/16/2023' : filterStore?.timestep === 'year_week' ? '10/02/2023' : '',
      x2: filterStore?.timestep === 'date' ? '10/31/2023' : filterStore?.timestep === 'year_week' ? '10/23/2023' : '',
      fillColor: '#B3F7CA',
    },
     {
      x: filterStore?.timestep === 'date' ? '10/23/2023': filterStore?.timestep === 'year_week' ? '10/16/2023' : '',
      borderColor: 'transparent',
      label: {
            orientation: "horizontal",
            borderColor: "#00E396",
            style: {
              color: "#fff",
              background: "#00E396",
              fontSize:'16px',
            },
            text: "After Cross Brand"
          }
    }
  ] : []
    },

  });

  return <Chart dir="ltr" type="line" series={adjustedSeries} options={chartOptions} sx={{ width: { xs: '100%', lg: '95%' } }} height={350} />;
}
