import React, { useCallback, useEffect, useRef, useState } from 'react';
import { TextField, Autocomplete, Chip, Checkbox, Box } from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import { resetLoadedKeyword, setFilterKeyword, setFilterLoadedKeyword } from 'src/context/reducers/filter-settings/filterSlice';
import { useLocales } from 'src/locales';
import { useLocation } from 'react-router';
import { use } from 'i18next';

const ITEMS_PER_BATCH = 100;

interface Keyword {
    search_term: string;
    total_organic_searches: any;
}

interface InfiniteScrollKeywordsProps {
    keywords: any[];
    onKeywordChange: (keyword: any) => void;

}

const InfiniteScrollKeywords: React.FC<InfiniteScrollKeywordsProps> = ({ keywords, onKeywordChange }) => {
    const filterStore = useSelector((state: any) => state.filters);
    const dispatch = useDispatch();
    const location = useLocation();
    // const [competitor, setCompetitor] = useState<string>('');
    const [loadedKeywords, setLoadedKeywords] = useState<Keyword[]>([]);
    const [batch, setBatch] = useState(0);
    const observer = useRef<IntersectionObserver | null>(null);
    const { t } = useLocales();

    useEffect(() => {
        const startIndex = batch * ITEMS_PER_BATCH;
        const endIndex = startIndex + ITEMS_PER_BATCH;
        setLoadedKeywords(prevLoadedKeywords => [
            ...prevLoadedKeywords,
            ...keywords.slice(startIndex, endIndex)
        ]);
    }, [batch, keywords]);

    useEffect(() => {
        setBatch(0);
        setLoadedKeywords(keywords.slice(0, ITEMS_PER_BATCH));
    }, [keywords]);

    const lastItemRef = useCallback((node: HTMLLIElement) => {
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && keywords.length > filterStore.loadedKeywords.length) {
                setBatch(prevBatch => prevBatch + 1);
            }
        });
        if (node) observer.current.observe(node);
    }, [filterStore, keywords.length]);

    useEffect(() => {
        const regex = /^\/dash\/competitor\/([^/]+)\/$/;
        const match = location.pathname.match(regex);

        if (location.pathname === '/dash/competitors-monitoring' || location.pathname === '/dash/competitor-focus') {
            return;
        }

        if (!match && loadedKeywords.length > 0) {
            dispatch(setFilterLoadedKeyword(loadedKeywords));
        }
        else {
            dispatch(setFilterLoadedKeyword(filterStore.loadedKeywords));
        }
    }, [dispatch, loadedKeywords, location]);

    return (
        <Box sx={{ width: { lg: 400, xs: '100%' } }}>
            <Autocomplete
                multiple
                sx={{ zIndex: '999' }}
                limitTags={1}
                size='small'
                disableCloseOnSelect
                options={filterStore.loadedKeywords}
                onChange={(event: any) => event.target.dataset.testid === "CloseIcon" && dispatch(setFilterKeyword([]))}
                getOptionLabel={(option: any) => option.search_term}
                renderInput={(params) => (
                    <TextField style={{ height: 5, position: 'relative', top: -8, zIndex: 999 }} {...params} label="Keywords" />
                )}
                value={filterStore?.keywords.length === 0 ? [t('dashboard.filters.all')] : filterStore?.keywords}
                renderOption={(props, option) => (
                    <li
                        {...props}
                        ref={filterStore.loadedKeywords.findIndex((kw: any) => kw === option) === filterStore.loadedKeywords.length - 1 ? lastItemRef : undefined}
                        onClick={() => onKeywordChange(option?.search_term)}
                    >
                        <div style={{ display: "flex", justifyContent: 'space-between', width: '100%' }}>
                            <div style={{ display: "flex", alignItems: 'center' }}>
                                <Checkbox
                                    checked={filterStore?.keywords.includes(option.search_term)}
                                    onChange={() => onKeywordChange(option?.search_term)}
                                />
                                <label style={{ cursor: 'pointer' }}>{option?.search_term}</label>
                            </div>
                            <div style={{ paddingRight: 10, paddingTop: 6, alignItems: 'center' }}>
                                <label style={{ cursor: 'pointer' }}>
                                    {(option?.total_organic_searches ?? 0).toFixed(0)}
                                </label>
                            </div>
                        </div>
                    </li>
                )}
                renderTags={(value, getTagProps) =>
                    value.map((option: any, index) =>
                        option === t('dashboard.filters.all') && value.length === 1 ? (
                            <Chip
                                label={t('dashboard.filters.all')}
                                {...getTagProps({ index: 0 })}
                                size="small"
                                variant="soft"
                                deleteIcon={option === t('dashboard.filters.all') ? <></> : undefined}
                            />
                        ) : (
                            <Chip
                                {...getTagProps({ index })}
                                key={option}
                                label={option}
                                size="small"
                                variant="soft"
                                onDelete={() => {
                                    const updatedKeywords = value.filter((_, idx) => idx !== index);
                                    dispatch(setFilterKeyword(updatedKeywords));
                                }}
                            />
                        )
                    )
                }
            />
        </Box>
    );
};

export default InfiniteScrollKeywords;
