import { useEffect, useState } from 'react';
import {
    Box,
    Typography,
    Button,
    DialogContent,
    DialogActions,
    DialogTitle,
    Dialog,
    Stepper,
    Step,
    StepLabel,
    TextField,
    Autocomplete,
    Chip,
    createFilterOptions
} from '@mui/material';
import { ComponentBlock } from 'src/sections/mui/component-block';
import Iconify from 'src/components/iconify';
import { ComponentBlockCustom } from 'src/sections/mui/component-block-custom';
import { useCurrentWorkspace } from 'src/context/reducers/app-settings';
import {
    useAddActiveModeMutation,
    useGetKeywordsKoBiddingQuery,
    useGetKeywordInfoKoBiddingQuery,
    useGetActiveModeQuery,
    useGetCampaignsQuery,
    useGetMatchTypeKoBiddingQuery,
    useGetCountriesQuery,
    useGetKeywordsQuery,
    useGetProfileQuery,
    useGetAdGroupKoBiddingQuery,
    useGetCountriesKoBiddingQuery,
    useAddKoBiddingMutation,
    useGetProfileKoBiddingQuery,
    useGetKoBiddingQuery
} from 'src/context/api/liveBidding/api';
import { useSnackbar } from 'notistack';
import { useLocation } from 'react-router';

interface AddBiddingStrategyModalProps {
    open: boolean;
    onClose: () => void;
}

const steps = ['Step 1: Basic Info', 'Step 2: Targeting', 'Step 3: Review'];

export default function AddBiddingStrategyModal({ open, onClose }: AddBiddingStrategyModalProps) {
    const [activeStep, setActiveStep] = useState(0);
    const [selection, setSelection] = useState('Search');
    const [subSelection, setSubSelection] = useState('Active Mode');
    const workspace: any = useCurrentWorkspace();
    const location = useLocation();

    // État pour gérer les erreurs
    const [keywordError, setKeywordError] = useState(false);
    const [keywordKoBiddingError, setKeywordKoBiddingError] = useState(false);
    const [countryError, setCountryError] = useState(false);
    const [platformError, setPlatformError] = useState(false);
    const [modeKoBiddingError, setModeKoBiddingError] = useState(false);
    const [campaignError, setCampaignError] = useState(false);
    const [adGroupKoBiddingError, setAdGroupKoBiddingError] = useState(false);
    const [campaignKoBiddingError, setCampaignKoBiddingError] = useState(false);
    const [matchTypeKoBiddingErrorState, setMatchTypeKoBiddingErrorState] = useState(false);
    const [deviceError, setDeviceError] = useState(false);
    const [profileError, setProfileError] = useState(false);
    const [profileActiveModeError, setProfileActiveModeError] = useState(false);

    const { enqueueSnackbar } = useSnackbar();

    const [addActiveMode] = useAddActiveModeMutation();
    const [addKoBidding] = useAddKoBiddingMutation();

    const { refetch: refetchActiveMode } = useGetActiveModeQuery({
        workspaceId: workspace?.id,
    });

    const { refetch: refetchKoBidding } = useGetKoBiddingQuery({
        workspaceId: workspace?.id,
    });

    const [formData, setFormData]: any = useState({
        keywords: [],
        country: [],
        account: '',
        frequency: '10 min',
        device_gads: ['desktop'],
        device: ['desktop'],
        campaign_name: [],
        campaign_id: [],
        home_language: "",
        domain: '',
        mode: "Active Mode",
        country_code: [],
        account_id: "",
        campaign_type: selection,
        client: '',
        workspaceId: null
    });

    const [formDataKoBidding, setFormDataKoBidding]: any = useState({
        client: '',
        status: true,
        mode: '',
        platform: 'Google Ads',
        account_id: '',
        campaign_type: selection,
        campaign_name: [],
        campaign_id: [],
        ad_group_name: '',
        ad_group_id: '',
        keyword_bid: '',
        match_type: '',
        enforce_max_cpc: false,
        max_cpc: 0.01,
        country: [],
        country_code: [],
        keyword_status: '',
        keyword_id: '',
        account: ''
    });

    const { data: keywordOptions, isLoading: isLoadingKeywords } = useGetKeywordsQuery({
        client: workspace?.bqTable,
    })

    const { data: keywordKoBiddingOptions, isLoading: isLoadingKoBiddingKeywords } = useGetKeywordsKoBiddingQuery({
        client: workspace?.bqTable,
    })

    const { data: matchTypeKoBiddingOptions, error: matchTypeKoBiddingError, isLoading: isLoadingMatchTypeKoBidding } = useGetMatchTypeKoBiddingQuery({
        client: workspace?.bqTable,
        keyword: formDataKoBidding?.keyword_bid
    }, {
        skip: formDataKoBidding?.keyword_bid === 0
    });

    const { data: profileOptions, isLoading: isLoadingProfile } = useGetProfileQuery({
        client: formData.client,
        keyword: formData.keywords
    }, {
        skip: formData.keywords.length === 0
    });

    const { data: profileKoBiddingOptions, isLoading: isLoadingProfileKoBidding } = useGetProfileKoBiddingQuery({
        client: formData.client,
        keyword: formDataKoBidding.keyword_bid,
        match_type: formDataKoBidding.match_type,
    }, {
        skip: formDataKoBidding.keyword_bid === 0 || formDataKoBidding.match_type === 0
    });

    const { data: campaignOptions, isLoading: isLoadingCampaigns } = useGetCampaignsQuery({
        client: formData.client,
        keyword: formData.keywords,
        profile: formData?.account,
        match_type: "Exact"
    }, {
        skip: (formData.keywords.length === 0 || !formData.account) // Vérifie si keywords est vide ou si profile est undefined
    });

    const { data: campaignOptionsKoBidding, isLoading: isLoadingCampaignsKoBidding } = useGetCampaignsQuery({
        client: formDataKoBidding.client,
        keyword: formDataKoBidding.keyword_bid,
        profile: formDataKoBidding?.account,
        match_type: formDataKoBidding?.match_type,
    }, {
        skip: (formDataKoBidding.keyword_bid === 0 || !formDataKoBidding.account) // Vérifie si keywords est vide ou si profile est undefined
    });

    const { data: adGroupOptionsKoBidding, isLoading: isLoadingAdGroupKoBidding } = useGetAdGroupKoBiddingQuery({
        client: formDataKoBidding.client,
        keyword: formDataKoBidding.keyword_bid,
        profile: formDataKoBidding?.account,
        campaign: formDataKoBidding?.campaign_name,
        match_type: formDataKoBidding?.match_type
    }, {
        skip: (formDataKoBidding.keyword_bid === 0 || !formDataKoBidding.account || !formDataKoBidding?.campaign_name) // Vérifie si keywords est vide ou si profile est undefined
    });

    const { data: countryOptions, isLoading: isLoadingCountries } = useGetCountriesQuery({
        client: formData.client,
    });

    const { data: countryKoBiddingOptions, isLoading: isLoadingCountriesKoBidding } = useGetCountriesKoBiddingQuery({
        client: workspace?.bqTable,
        keyword: formDataKoBidding.keyword_bid,
        campaign: formDataKoBidding?.campaign_name[0],
        profile: formDataKoBidding?.account,
        match_type: formDataKoBidding?.match_type
    });

    const { data: keywordInfo } = useGetKeywordInfoKoBiddingQuery({
        client: formDataKoBidding.client,
        keyword: formDataKoBidding.keyword_bid,
        campaign: formDataKoBidding.campaign_name[0], // Supposant que tu utilises la première campagne sélectionnée
        profile: formDataKoBidding.account,
        match_type: formDataKoBidding.match_type,
        ad_group_name: formDataKoBidding.ad_group_name,
    }, {
        skip: !formDataKoBidding.client || !formDataKoBidding.keyword_bid || !formDataKoBidding.ad_group_name // Ignore la requête si client ou keyword_bid n'est pas défini
    });

    useEffect(() => {
        if (keywordInfo) {
            setFormDataKoBidding((prevFormDataKoBidding: any) => ({
                ...prevFormDataKoBidding,
                keyword_id: keywordInfo[0].keyword_id || '',
                keyword_status: keywordInfo[0].keyword_status || ''
            }));
        }
    }, [keywordInfo])

    useEffect(() => {
        if (countryKoBiddingOptions && countryKoBiddingOptions.length > 0) {
            const countryData = countryKoBiddingOptions[0];
            setFormDataKoBidding((prevFormDataKoBidding: any) => ({
                ...prevFormDataKoBidding,
                country: countryData.country || [],
                country_code: countryData.country_code || []
            }));
        }
    }, [countryKoBiddingOptions]);

    useEffect(() => {
        if (workspace) {
            setFormData(
                {
                    ...formData,
                    domain: workspace?.url,
                    client: workspace?.bqTable,
                    workspaceId: workspace?.id
                }
            );

            setFormDataKoBidding(
                {
                    ...formDataKoBidding,
                    client: workspace?.bqTable,
                    workspaceId: workspace?.id
                }
            );
        }
    }, [workspace])

    const validateKoBiddingForm = () => {
        let hasError = false;

        if (!formDataKoBidding.keyword_bid) {
            setKeywordKoBiddingError(true);
            hasError = true;
        }

        if (!formDataKoBidding.mode) {
            setModeKoBiddingError(true);
            hasError = true;
        }

        if (!formDataKoBidding.campaign_name.length) {
            setCampaignKoBiddingError(true);
            hasError = true;
        }

        if (!formDataKoBidding.ad_group_name) {
            setAdGroupKoBiddingError(true);
            hasError = true;
        }

        if (!formDataKoBidding.platform) {
            setPlatformError(true);
            hasError = true;
        }

        if (!formDataKoBidding.account) {
            setProfileError(true);
            hasError = true;
        }

        if (!formDataKoBidding.match_type) {
            setMatchTypeKoBiddingErrorState(true);
            hasError = true;
        }

        return !hasError; // Si aucune erreur, retourne true
    };

    const handleNext = () => {
        let hasError = false;

        if (activeStep === 1) {
            if (subSelection === "Active Mode") {
                // Validation pour Active Mode
                if (formData.keywords?.length === 0) {
                    setKeywordError(true);
                    hasError = true;
                }

                if (!formData.country || formData.country.length === 0) {
                    setCountryError(true);
                    hasError = true;
                }


                if (!formData.account) {
                    setProfileActiveModeError(true);
                    hasError = true;
                }

                if (formData.campaign_name.length === 0) {
                    setCampaignError(true);
                    hasError = true;
                }

                if (formData.device.length === 0) {
                    setDeviceError(true);
                    hasError = true;
                }

                if (hasError) {
                    return;
                }
            } else if (subSelection === "Ko Bidding") {
                // Validation pour Ko Bidding
                if (!validateKoBiddingForm()) {
                    return; // Si la validation échoue, on arrête ici
                }
            }
        }

        setActiveStep((prevStep) => prevStep + 1);
    };

    const handleBack = () => setActiveStep((prevStep) => prevStep - 1);
    const isLastStep = activeStep === steps.length - 1;

    const handleFinishActiveMode = async () => {
        try {
            const result = await addActiveMode(formData).unwrap();
            enqueueSnackbar('Bidding strategy added successfully!', { variant: 'success' });
            refetchActiveMode();
            onClose();

            setActiveStep(0)
            setSelection("")

            setFormData({
                keywords: [],
                country: [],
                account: '',
                frequency: '10 min',
                device_gads: ['desktop'],
                device: ['desktop'],
                campaign_name: [],
                campaign_id: [],
                home_language: "",
                domain: '',
                mode: "Active Mode",
                country_code: [],
                account_id: "",
                campaign_type: selection,
                client: '',
                workspaceId: null
            });
        } catch (error) {
            enqueueSnackbar('Failed to add bidding strategy!', { variant: 'error' });
        }
    };

    const handleKeywordChange = (value: any) => {
        setFormData({
            ...formData,
            keywords: value,
            campaign_name: [],
            campaign_id: [],

        });

        if (value) {
            setKeywordError(false);
        }
    };

    const handleProfileChange = (value: any) => {

        setFormData({
            ...formData,
            account: value?.profile,
            account_id: value?.profile_id,
            campaign_name: [],
            campaign_id: [],
        });

        if (value) {
            setProfileActiveModeError(false);
        }
    };

    const handleDeviceGAdsChange = (deviceGads: string) => {
        let newDeviceGads = [...formData.device_gads];

        // Si le device_gads est déjà présent, on le retire, sinon on l'ajoute
        if (newDeviceGads.includes(deviceGads)) {
            newDeviceGads = newDeviceGads.filter(d => d !== deviceGads);
        } else {
            newDeviceGads.push(deviceGads);
        }

        // Mises à jour du champ `device` selon les règles de device_gads
        let newDevice: string[] = [];

        if (newDeviceGads.includes('desktop') && newDeviceGads.includes('mobile')) {
            newDevice = ['desktop', 'mobile'];  // Obligatoire les deux
        } else if (newDeviceGads.includes('desktop')) {
            newDevice = ['desktop'];  // Desktop obligatoire, mobile optionnel
        } else if (newDeviceGads.includes('mobile')) {
            newDevice = ['mobile'];  // Mobile obligatoire, desktop optionnel
        }

        // Mise à jour de formData avec les nouvelles valeurs
        setFormData({
            ...formData,
            device_gads: newDeviceGads,
            device: newDevice,  // Mettre à jour automatiquement le champ `device`
        });

        if (newDevice.length > 0) {
            setDeviceError(false);  // Réinitialise l'erreur si des devices sont sélectionnés
        }
    };

    const handleDeviceChange = (device: any) => {
        setFormData({ ...formData, device: device });
        if (device.length > 0) {
            setDeviceError(false);
        }
    };

    const handleCountryChange = (event: any, newValue: any) => {
        setFormData({
            ...formData,
            country: [newValue?.country_name] || [],
            country_code: [newValue?.country_code] || [],
            home_language: newValue?.home_language || ''
        });

        if (newValue) {
            setCountryError(false);
        }
    };

    const handleCampaignChange = (event: any, newValue: any) => {
        if (newValue && newValue.length > 0) {
            const campaign_names = newValue.map((campaign: any) => campaign.campaign_name);
            const campaign_ids = newValue.map((campaign: any) => campaign.campaign_id);

            const account_id = newValue[0].profile_id;

            setFormData({
                ...formData,
                campaign_name: campaign_names,
                campaign_id: campaign_ids,
                account_id: account_id
            });

            if (campaign_names.length > 0) {
                setCampaignError(false);
            }
        } else {
            setFormData({ ...formData, campaign_name: [], campaign_id: [], account_id: '' });
        }
    };

    const handlePlatformChange = (event: any, newValue: any) => {
        setFormDataKoBidding({
            ...formDataKoBidding,
            platform: newValue || '',
        });

        if (newValue)
            setPlatformError(false);
    };

    const handleKeywordKoBiddingChange = (event: any, newValue: any) => {
        setFormDataKoBidding({
            ...formDataKoBidding,
            keyword_bid: newValue?.keyword_bid || '',
            keyword_id: newValue?.keyword_id || '',
            keyword_status: newValue?.keyword_status || '',
            match_type: ''
        });

        if (newValue)
            setKeywordKoBiddingError(false);
    };

    const handleMatchTypeChange = (event: any, newValue: any) => {
        setFormDataKoBidding({
            ...formDataKoBidding,
            match_type: newValue || '',
        });

        if (newValue)
            setMatchTypeKoBiddingErrorState(false);
    };

    const handleAccountKoBiddingChange = (event: any, newValue: any) => {
        setFormDataKoBidding({
            ...formDataKoBidding,
            account: newValue?.profile || '', // Met à jour l'account
            account_id: newValue?.profile_id || '' // Met à jour l'account
        });

        if (newValue)
            setProfileError(false);
    };

    const handleModeKoBiddingChange = (event: any, newValue: any) => {
        setFormDataKoBidding({
            ...formDataKoBidding,
            mode: newValue || '', // Met à jour le mode
        });

        if (newValue)
            setModeKoBiddingError(false);
    };

    const handleCampaignKoBiddingChange = (newValue: any) => {
        setFormDataKoBidding((prevFormData: any) => ({
            ...prevFormData,
            campaign_name: [newValue.campaign_name],
            campaign_id: [newValue.campaign_id]
        }));

        if (newValue)
            setCampaignKoBiddingError(false);
    };

    const handleAdGroupKoBiddingChange = (newValue: any) => {
        setFormDataKoBidding({
            ...formDataKoBidding,
            ad_group_name: newValue.ad_group || '',
            ad_group_id: newValue.ad_group_id || ''
        });

        if (newValue)
            setAdGroupKoBiddingError(false);
    }

    const handleDecimalChange = (value: string) => {
        // Accepte les nombres avec zéro, ex: '0', '0.', '0.5', etc.
        const regex = /^(0|[1-9]\d*)(\.\d{0,2})?$/;

        // Validation directe via regex, autorise la saisie intermédiaire comme '0.'
        if (regex.test(value) || value === '' || value === '0') {
            setFormDataKoBidding({
                ...formDataKoBidding,
                max_cpc: value, // Stocke la valeur même si c'est un zéro
            });
        } else {
            setFormDataKoBidding({
                ...formDataKoBidding,
                max_cpc: '', // Réinitialise si le format est incorrect
            });
        }
    };

    const handleFinishKoBidding = async () => {
        try {

            // Convertir la valeur max_cpc en float avant d'envoyer le formulaire
            const formDataWithFloatMaxCPC = {
                ...formDataKoBidding,
                max_cpc: parseFloat(formDataKoBidding.max_cpc), // Conversion en float
            };

            const result = await addKoBidding(formDataWithFloatMaxCPC).unwrap();
            enqueueSnackbar('KO Bidding strategy added successfully!', { variant: 'success' });
            refetchKoBidding();
            onClose();

            setActiveStep(0)
            setSelection("")

            setFormDataKoBidding({
                client: '',
                status: true,
                mode: '',
                platform: '',
                account_id: '',
                campaign_type: '',
                campaign_name: [],
                campaign_id: [],
                ad_group_name: '',
                ad_group_id: '',
                keyword_bid: '',
                match_type: '',
                enforce_max_cpc: false,
                max_cpc: 0.01,
                country: [],
                country_code: [],
                keyword_status: '',
                keyword_id: '',
                account: ''
            });

        } catch (error) {
            enqueueSnackbar('Failed to add KO Bidding strategy!', { variant: 'error' });
        }
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="md"
            fullWidth
        >
            <DialogTitle>Add Bidding Strategy</DialogTitle>
            <DialogContent>
                <Stepper activeStep={activeStep} alternativeLabel>
                    {steps.map((label) => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>

                <Box sx={{ mt: 4 }}>
                    {activeStep === 0 && (
                        <StepOneContent
                            selection={selection}
                            setSelection={setSelection}
                            subSelection={subSelection}
                            setSubSelection={setSubSelection}
                            setFormData={setFormData}
                        />
                    )}
                    {activeStep === 1 && subSelection === 'Active Mode' && (
                        <StepTwoContent
                            isLoadingCampaigns={isLoadingCampaigns}
                            campaignOptions={campaignOptions}
                            profileOptions={profileOptions}
                            isLoadingProfile={isLoadingProfile}
                            keywordOptions={keywordOptions}
                            isLoadingKeywords={isLoadingKeywords}
                            isLoadingCountries={isLoadingCountries}
                            countriesOptions={countryOptions}
                            formData={formData}
                            handleProfileChange={handleProfileChange}
                            handleKeywordChange={handleKeywordChange}
                            handleCountryChange={handleCountryChange}
                            handleDeviceGAdsChange={handleDeviceGAdsChange}
                            handleDeviceChange={handleDeviceChange}
                            handleCampaignChange={handleCampaignChange}
                            keywordError={keywordError}
                            profileError={profileError}
                            countryError={countryError}
                            campaignError={campaignError}
                            deviceError={deviceError}
                            profileActiveModeError={profileActiveModeError}
                            subSelection={subSelection}
                            selection={selection}
                        />
                    )}

                    {activeStep === 1 && subSelection === 'Ko Bidding' && (
                        <StepTwoContentKoBidding
                            formDataKoBidding={formDataKoBidding}
                            handlePlatformChange={handlePlatformChange}
                            handleKeywordKoBiddingChange={handleKeywordKoBiddingChange}
                            keywordKoBiddingOptions={keywordKoBiddingOptions}
                            isLoadingKoBiddingKeywords={isLoadingKoBiddingKeywords}
                            handleMatchTypeChange={handleMatchTypeChange}
                            matchTypeKoBiddingOptions={matchTypeKoBiddingOptions}
                            isLoadingMatchTypeKoBidding={isLoadingMatchTypeKoBidding}
                            handleModeKoBiddingChange={handleModeKoBiddingChange}
                            handleAccountKoBiddingChange={handleAccountKoBiddingChange}
                            profileKoBiddingOptions={profileKoBiddingOptions}
                            isLoadingProfileKoBidding={isLoadingProfileKoBidding}
                            campaignKoBiddingError={campaignKoBiddingError}
                            profileError={profileError}
                            modeKoBiddingError={modeKoBiddingError}
                            adGroupKoBiddingError={adGroupKoBiddingError}
                            handleCampaignKoBiddingChange={handleCampaignKoBiddingChange}
                            matchTypeKoBiddingErrorState={matchTypeKoBiddingErrorState}
                            campaignOptionsKoBidding={campaignOptionsKoBidding}
                            keywordKoBiddingError={keywordKoBiddingError}
                            isLoadingCampaignsKoBidding={isLoadingCampaignsKoBidding}
                            isLoadingAdGroupKoBidding={isLoadingAdGroupKoBidding}
                            platformError={platformError}
                            handleAdGroupKoBiddingChange={handleAdGroupKoBiddingChange}
                            adGroupOptionsKoBidding={adGroupOptionsKoBidding}
                            handleDecimalChange={handleDecimalChange}
                        />
                    )}

                    {/* Mise à jour de l'étape 3 */}
                    {activeStep === 2 && (
                        <>
                            {subSelection === 'Active Mode' ? <StepThreeContent formData={formData} /> : <StepThreeContentKoBidding formDataKoBidding={formDataKoBidding} />}
                        </>
                    )}

                </Box>
            </DialogContent>
            <DialogActions>
                <Button variant="text" onClick={onClose} sx={{ color: 'white' }}>
                    Cancel
                </Button>
                {activeStep !== 0 && (
                    <Button variant="contained" onClick={handleBack}>
                        Back
                    </Button>
                )}
                <Button variant="contained" onClick={isLastStep ? subSelection === "Active Mode" ? handleFinishActiveMode : handleFinishKoBidding : handleNext}>
                    {isLastStep ? 'Finish' : 'Next'}
                </Button>
            </DialogActions>
        </Dialog>
    );
}


// Step 1: Basic Info
const StepOneContent = ({ selection, setSelection, subSelection, setSubSelection, setFormData }: any) => {
    const buttonStyle = (isSelected: boolean) => ({
        backgroundColor: isSelected ? 'black' : 'transparent',
        color: 'white',
        border: `1px solid ${isSelected ? 'black' : 'gray'}`,
        width: '150px',
        height: '50px',
        margin: '10px',
    });

    const handleSelection = (value: string) => {
        setSelection(value);
    };

    const handleSubSelection = (value: string) => {
        setSubSelection(value);
    };

    return (
        <Box>
            <ComponentBlock sx={{ marginTop: 7 }} title="Choose your bidding type">
                <Box display="flex" justifyContent="row">
                    <Button
                        style={buttonStyle(selection === 'Search')}
                        onClick={() => handleSelection('Search')}
                    >
                        Search
                    </Button>
                </Box>
            </ComponentBlock>

            {selection === 'Search' && (
                <ComponentBlock sx={{ marginTop: 4, mb: 1 }} title="Choose your search mode">
                    <Box display="flex" justifyContent="row">
                        <Button
                            style={buttonStyle(subSelection === 'Active Mode')}
                            onClick={() => handleSubSelection('Active Mode')}
                        >
                            Active Mode
                        </Button>
                        <Button
                            style={buttonStyle(subSelection === 'Ko Bidding')}
                            onClick={() => handleSubSelection('Ko Bidding')}
                        >
                            Ko Bidding
                        </Button>
                    </Box>
                </ComponentBlock>
            )}
        </Box>
    );
};

// Step 2: Formulaire Active Mode
const StepTwoContent = ({ formData, profileOptions, isLoadingProfile, campaignOptions, profileActiveModeError, isLoadingCampaigns, isLoadingCountries, countriesOptions, handleKeywordChange, keywordOptions, isLoadingKeywords, keywordError, deviceError, campaignError, countryError, profileError, handleProfileChange, handleCountryChange, handleDeviceChange, handleDeviceGAdsChange, handleCampaignChange }: any) => {
    const isKeywordSelected = formData.keywords.length > 0; // Détermine si un mot-clé est sélectionné
    const isProfileSelected = formData.account.length > 0; // Détermine si un mot-clé est sélectionné

    return (
        <>
            <Box>
                {/* Keywords Field */}
                <ComponentBlock sx={{ marginTop: 5 }} title="Keyword (Exact Match)">
                    <Box sx={{ width: '100%' }}>
                        <Autocomplete
                            disableClearable
                            multiple={false}
                            options={isLoadingKeywords ? [] : keywordOptions || []}
                            value={keywordOptions.find((option: any) => option === formData.keywords) || null}  // Gestion de la valeur actuelle
                            onChange={(event, newValue) => handleKeywordChange(newValue)}

                            // Personnalisation du filtrage et du tri des options
                            filterOptions={(options, { inputValue }) => {
                                const lowerInputValue = inputValue.toLowerCase();

                                // Correspondance exacte
                                const exactMatches = options.filter((option) =>
                                    option.toLowerCase() === lowerInputValue
                                );

                                // Correspondances partielles
                                const partialMatches = options.filter((option) =>
                                    option.toLowerCase().includes(lowerInputValue) &&
                                    option.toLowerCase() !== lowerInputValue
                                );

                                // Combine exact matches en premier, puis partial matches
                                return [...exactMatches, ...partialMatches];
                            }}

                            renderTags={(value: string[], getTagProps) =>
                                value.map((option: string, index: number) => (
                                    <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                                ))
                            }

                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    label="Keyword (Exact Match)"
                                    placeholder="Enter keywords"
                                    error={keywordError}
                                    helperText={keywordError ? "At least one keyword is required" : ""}
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                borderColor: keywordError ? 'red' : '',
                                            },
                                        },
                                    }}
                                />
                            )}
                        />

                    </Box>
                </ComponentBlock>

                <ComponentBlock sx={{ marginTop: 3 }} title="Account">
                    <Box sx={{ width: '100%' }}>
                        <Autocomplete
                            disableClearable
                            disabled={!isKeywordSelected}
                            multiple={false}
                            options={isLoadingProfile ? [] : profileOptions || []}
                            value={profileOptions && profileOptions.find((option: any) => option.profile === formData.account)}  // Correspond à une option
                            getOptionLabel={(option: any) => option.profile}  // Affiche le profil dans la liste déroulante
                            onChange={(event, newValue) => handleProfileChange(newValue)}
                            renderTags={(value: string[], getTagProps) =>
                                value.map((option: string, index: number) => (
                                    <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                                ))
                            }
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    label="Account"
                                    error={profileActiveModeError}
                                    helperText={profileActiveModeError ? "At least one account is required" : ""}
                                    placeholder="Enter account"
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            // Applique une bordure rouge lorsque le champ est désactivé
                                            '&.Mui-disabled': {
                                                border: profileActiveModeError ? '1px solid red' : '',
                                            },
                                            // Applique la couleur de la bordure lorsque le champ est en erreur
                                            '& fieldset': {
                                                borderColor: profileActiveModeError ? 'red' : '',
                                            },
                                        },
                                    }}
                                />
                            )}

                        />
                    </Box>
                </ComponentBlock>

                {/* campaign_name Field */}
                <ComponentBlock sx={{ marginTop: 3 }} title="Campaign(s) Selection">
                    <Box sx={{ width: '100%' }}>
                        <Autocomplete
                            disableClearable
                            multiple
                            disabled={(!isKeywordSelected || !isProfileSelected)}
                            options={isLoadingCampaigns ? [] : campaignOptions || []}  // options = tableau d'objets campagne
                            getOptionLabel={(option: any) => option?.campaign_name || ''}  // Gérer l'affichage du nom des campagnes
                            value={campaignOptions?.filter((option: any) => formData.campaign_name.includes(option.campaign_name)) || []}  // Assurez-vous que la sélection actuelle correspond à ce qui est dans formData
                            onChange={handleCampaignChange}  // Met à jour formData avec les campagnes sélectionnées
                            renderTags={(value: any[], getTagProps) =>
                                value.map((option: any, index: number) => (
                                    <Chip
                                        label={option.campaign_name}
                                        {...getTagProps({ index })}
                                    />
                                ))
                            }
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    label="Campaign(s)"
                                    placeholder="Select a campaign"
                                    error={campaignError}
                                    helperText={campaignError ? "At least one campaign is required" : ""}
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            // Applique une bordure rouge lorsque le champ est désactivé
                                            '&.Mui-disabled': {
                                                border: campaignError ? '1px solid red' : '',
                                            },
                                            // Applique la couleur de la bordure lorsque le champ est en erreur
                                            '& fieldset': {
                                                borderColor: campaignError ? 'red' : '',
                                            },
                                        },
                                    }}
                                />
                            )}
                        />

                    </Box>
                </ComponentBlock>

                {/* Country Field */}
                <ComponentBlock sx={{ marginTop: 3 }} title="Country">
                    <Box sx={{ width: '100%' }}>
                        <Autocomplete
                            disableClearable
                            options={isLoadingCountries ? [] : countriesOptions || []}
                            getOptionLabel={(option: any) => option.country_name || ''}
                            onChange={handleCountryChange}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    label="Country"
                                    placeholder="Select a country"
                                    error={countryError}
                                    helperText={countryError ? "Country is required" : ""}
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                borderColor: countryError ? 'red' : '',
                                            },
                                        },
                                    }}
                                />
                            )}
                        />
                    </Box>
                </ComponentBlock>

                <ComponentBlock sx={{ marginTop: 3 }} title="Active Mode Device(s)">
                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: 4 }}>
                        <Box
                            onClick={() => handleDeviceGAdsChange('desktop')}
                            sx={{ display: 'flex', flexDirection: 'row', cursor: 'pointer' }}
                        >
                            <Iconify color={formData.device_gads.includes('desktop') ? "#8339f6" : "#9faab7"} fontSize={16} mr={1} icon={"ph:desktop"} />
                            <Typography fontSize={15} variant="body2">Scrap Desktop Results</Typography>
                        </Box>
                        <Box
                            onClick={() => handleDeviceGAdsChange('mobile')}
                            sx={{ display: 'flex', flexDirection: 'row', cursor: 'pointer' }}
                        >
                            <Iconify color={formData.device_gads.includes('mobile') ? "#8339f6" : "#9faab7"} fontSize={16} mr={1} icon={"clarity:mobile-solid"} />
                            <Typography fontSize={15} variant="body2">Scrap Mobile Results</Typography>
                        </Box>
                    </Box>
                </ComponentBlock>

                {/* Scraping Device(s) Field */}
                <ComponentBlock sx={{ marginTop: 3, mb: 2 }} title="Scraping Device(s)">
                    <Box sx={{ width: '100%' }}>
                        <Autocomplete
                            disableClearable
                            multiple
                            options={["desktop", "mobile"]}
                            value={formData.device}
                            onChange={(event, newValue) => handleDeviceChange(newValue)}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    label="Scraping Device(s)"
                                    placeholder="Select devices"
                                    error={deviceError}
                                    helperText={deviceError ? "At least one device is required" : ""}
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                borderColor: deviceError ? 'red' : '',
                                            },
                                        },
                                    }}
                                />
                            )}
                        />
                    </Box>
                </ComponentBlock>
            </Box>
        </>
    );
};

const StepTwoContentKoBidding = ({ formDataKoBidding, modeKoBiddingError, adGroupKoBiddingError, keywordKoBiddingError, platformError, campaignKoBiddingError, profileError, matchTypeKoBiddingErrorState, handleAdGroupKoBiddingChange, handleDecimalChange, adGroupOptionsKoBidding, isLoadingAdGroupKoBidding, isLoadingCampaignsKoBidding, campaignOptionsKoBidding, handleCampaignKoBiddingChange, profileKoBiddingOptions, isLoadingProfileKoBidding, handleAccountKoBiddingChange, handleModeKoBiddingChange, handleMatchTypeChange, isLoadingMatchTypeKoBidding, matchTypeKoBiddingOptions, handlePlatformChange, handleKeywordKoBiddingChange, keywordKoBiddingOptions, isLoadingKoBiddingKeywords }: any) => {


    return (
        <>
            <ComponentBlock sx={{ marginTop: 3 }} title="Keyword">
                <Box sx={{ width: '100%' }}>
                    <Autocomplete
                        disableClearable
                        options={isLoadingKoBiddingKeywords ? [] : keywordKoBiddingOptions}
                        getOptionLabel={(option: any) => option.keyword_bid || ''}
                        value={keywordKoBiddingOptions.find((option: any) => option.keyword_bid === formDataKoBidding.keyword_bid) || null}
                        onChange={handleKeywordKoBiddingChange}
                        isOptionEqualToValue={(option, value) => option.keyword_bid === value?.keyword_bid}

                        // Personnalisation du filtrage et du tri des options
                        filterOptions={(options, { inputValue }) => {
                            const lowerInputValue = inputValue.toLowerCase();

                            // Correspondance exacte
                            const exactMatches = options.filter((option) =>
                                option.keyword_bid.toLowerCase() === lowerInputValue
                            );

                            // Correspondances partielles
                            const partialMatches = options.filter((option) =>
                                option.keyword_bid.toLowerCase().includes(lowerInputValue) &&
                                option.keyword_bid.toLowerCase() !== lowerInputValue
                            );

                            // Combine exact matches en premier, puis partial matches
                            return [...exactMatches, ...partialMatches];
                        }}

                        renderOption={(props, option, index) => (
                            <li {...props} key={option.keyword_bid}>
                                {option.keyword_bid}
                            </li>
                        )}

                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label="Keyword"
                                placeholder="Select a keyword"
                                error={keywordKoBiddingError}
                                helperText={keywordKoBiddingError ? "At least one keyword is required" : ""}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderColor: keywordKoBiddingError ? 'red' : '',
                                        },
                                    },
                                }}
                            />
                        )}
                    />
                </Box>
            </ComponentBlock>

            {/* Match Type Field */}
            <ComponentBlock sx={{ marginTop: 3 }} title="Match Type">
                <Box sx={{ width: '100%' }}>
                    <Autocomplete
                        disableClearable
                        disabled={!formDataKoBidding.keyword_bid}
                        options={isLoadingMatchTypeKoBidding ? [] : matchTypeKoBiddingOptions || []}
                        value={formDataKoBidding.match_type}
                        onChange={handleMatchTypeChange}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label="Match Type"
                                placeholder="Select a match type"
                                error={matchTypeKoBiddingErrorState}
                                helperText={matchTypeKoBiddingErrorState ? "At least one match type is required" : ""}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        // Applique une bordure rouge lorsque le champ est désactivé
                                        '&.Mui-disabled': {
                                            border: matchTypeKoBiddingErrorState ? '1px solid red' : '',
                                        },
                                        // Applique la couleur de la bordure lorsque le champ est en erreur
                                        '& fieldset': {
                                            borderColor: matchTypeKoBiddingErrorState ? 'red' : '',
                                        },
                                    },
                                }}
                            />
                        )}
                    />
                </Box>
            </ComponentBlock>

            {/* Account Field */}
            <ComponentBlock sx={{ marginTop: 3 }} title="Account">
                <Box sx={{ width: '100%' }}>
                    <Autocomplete
                        disableClearable
                        disabled={!formDataKoBidding.keyword_bid}  // Désactiver tant qu'aucun mot-clé n'est sélectionné
                        options={isLoadingProfileKoBidding ? [] : profileKoBiddingOptions || []}
                        getOptionLabel={(option: any) => option.profile}  // Affiche le profil dans la liste déroulante
                        value={profileKoBiddingOptions && profileKoBiddingOptions.find((option: any) => option.profile === formDataKoBidding.account)}  // Correspond à une option
                        onChange={handleAccountKoBiddingChange}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label="Account"
                                placeholder="Select an account"
                                error={profileError}
                                helperText={profileError ? "At least one account is required" : ""}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        // Applique une bordure rouge lorsque le champ est désactivé
                                        '&.Mui-disabled': {
                                            border: profileError ? '1px solid red' : '',
                                        },
                                        // Applique la couleur de la bordure lorsque le champ est en erreur
                                        '& fieldset': {
                                            borderColor: profileError ? 'red' : '',
                                        },
                                    },
                                }}
                            />
                        )}
                    />
                </Box>
            </ComponentBlock>

            <ComponentBlock sx={{ marginTop: 3 }} title="Campaign Selection">
                <Box sx={{ width: '100%' }}>
                    <Autocomplete
                        multiple={false} // Désactive la sélection multiple
                        disableClearable
                        options={isLoadingCampaignsKoBidding ? [] : campaignOptionsKoBidding || []}
                        getOptionLabel={(option: any) => option.campaign_name || ''}  // Affiche les noms des campagnes
                        value={campaignOptionsKoBidding?.find((option: any) => option.campaign_name === formDataKoBidding.campaign_name[0]) || null}  // Utilise la première valeur du tableau si c'est un tableau
                        onChange={(event, newValue) => handleCampaignKoBiddingChange(newValue)}  // Met à jour l'état avec l'option sélectionnée
                        isOptionEqualToValue={(option, value) => option.campaign_id === value.campaign_id}  // Comparaison entre option et valeur sélectionnée
                        renderOption={(props, option, index) => (
                            <li {...props} key={option.campaign_id || index}>
                                {option.campaign_name}
                            </li>
                        )}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label="Campaign"
                                placeholder="Select a campaign"
                                error={campaignKoBiddingError}
                                helperText={campaignKoBiddingError ? "At least one campaign is required" : ""}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        // Applique une bordure rouge lorsque le champ est désactivé
                                        '&.Mui-disabled': {
                                            border: campaignKoBiddingError ? '1px solid red' : '',
                                        },
                                        // Applique la couleur de la bordure lorsque le champ est en erreur
                                        '& fieldset': {
                                            borderColor: campaignKoBiddingError ? 'red' : '',
                                        },
                                    },
                                }}
                            />
                        )}
                    />
                </Box>
            </ComponentBlock>

            <ComponentBlock sx={{ marginTop: 3 }} title="Ad Group Selection">
                <Box sx={{ width: '100%' }}>
                    <Autocomplete
                        disableClearable
                        disabled={
                            formDataKoBidding.keyword_bid === '' ||
                            formDataKoBidding.account === '' ||
                            formDataKoBidding.campaign_name.length === 0
                        }
                        multiple={false} // Sélection simple pour ad group
                        options={isLoadingAdGroupKoBidding ? [] : adGroupOptionsKoBidding || []}
                        getOptionLabel={(option: any) => option.ad_group || ''} // Affiche les noms des ad groups
                        value={adGroupOptionsKoBidding?.find((option: any) => option.ad_group_id === formDataKoBidding.ad_group_id) || null} // Correspond à l'ad group sélectionné
                        onChange={(event, newValue) => handleAdGroupKoBiddingChange(newValue)} // Met à jour ad_group_id et ad_group_name
                        renderOption={(props, option) => (
                            <li {...props} key={option.ad_group_id}> {/* Utilise une clé unique */}
                                {option.ad_group}
                            </li>
                        )}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label="Ad Group"
                                placeholder="Select an ad group"
                                error={adGroupKoBiddingError}
                                helperText={adGroupKoBiddingError ? "At least one ad group is required" : ""}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        // Applique une bordure rouge lorsque le champ est désactivé
                                        '&.Mui-disabled': {
                                            border: adGroupKoBiddingError ? '1px solid red' : '',
                                        },
                                        // Applique la couleur de la bordure lorsque le champ est en erreur
                                        '& fieldset': {
                                            borderColor: adGroupKoBiddingError ? 'red' : '',
                                        },
                                    },
                                }}
                            />
                        )}
                    />
                </Box>
            </ComponentBlock>

            {/* Mode Field */}
            <ComponentBlock sx={{ marginTop: 3 }} title="Mode">
                <Box sx={{ width: '100%' }}>
                    <Autocomplete
                        disableClearable
                        options={["KO Bidding Manual", "KO Bidding Blended CTR", "KO Bidding Ad CTR"]}
                        value={formDataKoBidding.mode}
                        onChange={handleModeKoBiddingChange}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label="Mode"
                                placeholder="Select a mode"
                                error={modeKoBiddingError}
                                helperText={modeKoBiddingError ? "At least one mode is required" : ""}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        // Applique une bordure rouge lorsque le champ est désactivé
                                        '&.Mui-disabled': {
                                            border: modeKoBiddingError ? '1px solid red' : '',
                                        },
                                        // Applique la couleur de la bordure lorsque le champ est en erreur
                                        '& fieldset': {
                                            borderColor: modeKoBiddingError ? 'red' : '',
                                        },
                                    },
                                }}
                            />
                        )}
                    />
                </Box>
            </ComponentBlock>

            {/* Platform Field */}
            <ComponentBlock sx={{ marginTop: 3 }} title="Platform">
                <Box sx={{ width: '100%' }}>
                    <Autocomplete
                        disableClearable
                        options={["SA360", "Google Ads"]}
                        value={formDataKoBidding.platform}
                        onChange={handlePlatformChange}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label="Platform"
                                placeholder="Select a platform"
                                error={platformError}
                                helperText={platformError ? "At least one platform is required" : ""}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        // Applique une bordure rouge lorsque le champ est désactivé
                                        '&.Mui-disabled': {
                                            border: platformError ? '1px solid red' : '',
                                        },
                                        // Applique la couleur de la bordure lorsque le champ est en erreur
                                        '& fieldset': {
                                            borderColor: platformError ? 'red' : '',
                                        },
                                    },
                                }}
                            />
                        )}
                    />
                </Box>
            </ComponentBlock>


            <ComponentBlock sx={{ marginTop: 3 }} title="Max CPC (0.01 - 30)">
                <Box sx={{ width: '100%' }}>
                    <TextField
                        variant="outlined"
                        placeholder="Max CPC"
                        type="text" // Changez le type en 'text' pour permettre la saisie de '0'
                        value={formDataKoBidding.max_cpc}
                        onChange={(event) => handleDecimalChange(event.target.value)} // Appelle la fonction de validation
                        error={parseFloat(formDataKoBidding.max_cpc) < 0.01 || parseFloat(formDataKoBidding.max_cpc) > 30} // Vérifie que la valeur est bien dans la plage 0.01-30
                        helperText={
                            (parseFloat(formDataKoBidding.max_cpc) < 0.01 || parseFloat(formDataKoBidding.max_cpc) > 30)
                                ? "Value must be between 0.01 and 30"
                                : ""
                        }
                        fullWidth
                    />
                </Box>
            </ComponentBlock>


        </>
    );
};


// Step 3: Review
const StepThreeContent = ({ formData }: any) => {
    return (
        <Box sx={{ mt: 4 }}>
            <ComponentBlock sx={{ marginTop: 2 }} title="Review Your Selections(s)">
                <ComponentBlockCustom sx={{ marginTop: 2 }} title="Bidding Type">
                    <Typography variant="body1">Bidding Type: {formData.campaign_type}</Typography>
                    <Typography variant="body1">Search Mode: {formData.mode}</Typography>
                </ComponentBlockCustom>

                <ComponentBlockCustom sx={{ marginTop: 2 }} title="Keyword (Exact Match)">
                    <Chip label={formData.keywords} sx={{ mr: 1, mb: 1 }} />

                </ComponentBlockCustom>

                <ComponentBlockCustom sx={{ marginTop: 2 }} title="Campaign(s)">
                    <Typography variant="body1">
                        {formData.campaign_name}
                    </Typography>
                </ComponentBlockCustom>

                <ComponentBlockCustom sx={{ marginTop: 2 }} title="Country">
                    <Typography variant="body1">
                        {formData.country}
                    </Typography>
                </ComponentBlockCustom>

                <ComponentBlockCustom sx={{ marginTop: 2, mb: 2 }} title="Active Mode Device(s)">
                    <Typography variant="body1">
                        {formData.device_gads.join(", ")}
                    </Typography>
                </ComponentBlockCustom>

                <ComponentBlockCustom sx={{ marginTop: 2, mb: 2 }} title="Scraping Device(s)">
                    <Typography variant="body1">
                        {formData.device.join(", ")}
                    </Typography>
                </ComponentBlockCustom>
            </ComponentBlock>
        </Box>
    );
};

// Step 3: Review pour KO Bidding
const StepThreeContentKoBidding = ({ formDataKoBidding }: any) => {
    return (
        <Box sx={{ mt: 4 }}>
            <ComponentBlock sx={{ marginTop: 2 }} title="Review Your KO Bidding Strategy">
                <ComponentBlockCustom sx={{ marginTop: 2 }} title="Client">
                    <Typography variant="body1">{formDataKoBidding.client}</Typography>
                </ComponentBlockCustom>

                <ComponentBlockCustom sx={{ marginTop: 2 }} title="Platform">
                    <Typography variant="body1">{formDataKoBidding.platform}</Typography>
                </ComponentBlockCustom>

                <ComponentBlockCustom sx={{ marginTop: 2 }} title="Keyword">
                    <Typography variant="body1">{formDataKoBidding.keyword_bid}</Typography>
                </ComponentBlockCustom>

                <ComponentBlockCustom sx={{ marginTop: 2 }} title="Match Type">
                    <Typography variant="body1">{formDataKoBidding.match_type}</Typography>
                </ComponentBlockCustom>

                <ComponentBlockCustom sx={{ marginTop: 2 }} title="Account">
                    <Typography variant="body1">{formDataKoBidding.account}</Typography>
                </ComponentBlockCustom>

                <ComponentBlockCustom sx={{ marginTop: 2 }} title="Campaign Name">
                    <Typography variant="body1">{formDataKoBidding.campaign_name}</Typography>
                </ComponentBlockCustom>

                <ComponentBlockCustom sx={{ marginTop: 2 }} title="Ad Group">
                    <Typography variant="body1">{formDataKoBidding.ad_group_name}</Typography>
                </ComponentBlockCustom>

                <ComponentBlockCustom sx={{ marginTop: 2 }} title="Max CPC">
                    <Typography variant="body1">{formDataKoBidding.max_cpc}</Typography>
                </ComponentBlockCustom>
            </ComponentBlock>
        </Box>
    );
};
