import { useState, useCallback, useEffect } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import { CardProps } from '@mui/material/Card';
import IconButton from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';
import AvatarGroup, { avatarGroupClasses } from '@mui/material/AvatarGroup';
import { useBoolean } from 'src/hooks/use-boolean';
import { useCopyToClipboard } from 'src/hooks/use-copy-to-clipboard';
import { fData } from 'src/utils/format-number';
import Iconify from 'src/components/iconify';
import { useSnackbar } from 'src/components/snackbar';
import { ConfirmDialog } from 'src/components/custom-dialog';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import FileManagerShareDialog from './file-manager-share-dialog';
import FileManagerFileDetails from './file-manager-file-details';
import { Typography } from '@mui/material';
import { useRouter } from 'src/routes/hooks';
import { paths } from 'src/routes/paths';
import { useCurrentUserQuery } from 'src/context/api/auth';
import { use } from 'i18next';
import { fontSize, useTheme } from '@mui/system';
import { useLocales } from 'src/locales';
import { useDispatch } from 'react-redux';
import { get } from 'lodash';


interface Props extends CardProps {
  folder: any;
  selected?: boolean;
  active?: boolean;
  bqTables?: any;
  onSelect?: VoidFunction;
  onDelete: VoidFunction;
  onEdit: VoidFunction;
  onUpdate?: (item: any) => void;
  onInvitation?: (item: any) => void;
}

export default function FileManagerFolderItem({
  folder,
  selected,
  active,
  bqTables,
  onSelect,
  onDelete,
  onEdit,
  onUpdate,
  onInvitation,
  sx,
  ...other
}: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useLocales();
  const { copy } = useCopyToClipboard();
  const [inviteEmail, setInviteEmail] = useState('');
  const [folderName, setFolderName] = useState(folder.name);
  const editFolder = useBoolean();
  const checkbox = useBoolean();
  const share = useBoolean();
  const popover = usePopover();
  const confirm = useBoolean();
  const [openDetails, setOpenDetails] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const details = useBoolean(false);//openDetails || 
  const favorite = useBoolean(folder.isFavorited);
  const theme = useTheme();
  const dispatch = useDispatch();
  const [workspace, setWorkspace] = useState<any>(null);
  const [pendingInvitations, setPendingInvitations] = useState<any>([]);
  const [activeUsers, setActiveUsers] = useState<any>([]);

  useEffect(() => {
    if (folder.invitations) {
      setPendingInvitations(folder.invitations)
    }
  }, [folder.invitations])

  useEffect(() => {
    if (folder.users) {
      setActiveUsers(folder.users)
    }

  }, [folder.users])


  const handleChangeInvite = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setInviteEmail(event.target.value);
  }, []);


  const handleCopy = useCallback(() => {
    enqueueSnackbar(t('dashboard.global.copied'));
    copy(folder.url);
  }, [copy, enqueueSnackbar, folder.url]);


  const router = useRouter()

  const user: any = useCurrentUserQuery();

  const handlePopover = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    details.onTrue();
  }

  const renderAction = (
    <Stack
      direction="row"
      alignItems="center"
      sx={{
        top: 8,
        right: 8,
        position: 'absolute',
        zIndex: 10,
      }}
    >
      <IconButton
        className="no-activate"
        color={popover.open ? 'inherit' : 'default'}
        onClick={handlePopover}
      >
        <Iconify icon="material-symbols:settings" />
      </IconButton>
    </Stack>
  );

  const renderIcon =
    (checkbox.value || selected) && onSelect ? (
      <Checkbox
        size="medium"
        checked={selected}
        onClick={onSelect}
        icon={<Iconify icon="eva:radio-button-off-fill" />}
        checkedIcon={<Iconify icon="eva:checkmark-circle-2-fill" />}
        sx={{ p: 0.75 }}
      />
    ) : (
      <Stack direction="row" alignItems="center" spacing={1} >
        <Box sx={{
          p: 1,
          backgroundColor: theme.palette.mode === 'dark' ? '#28323D' : ' #F4F6F8',
          color: theme.palette.mode === 'dark' ? '#ffffff' : '#000000',
          borderRadius: '10px',
          padding: '10px'
        }}>
          <Box component="img" src={ folder?.name === "Demo" ? '/assets/logo.png' :`https://www.google.com/s2/favicons?domain=${folder?.url}&sz=64`} sx={{ width: 24, height: 24, borderRadius: '5px' }} />
        </Box>


        {active === true &&
          <div style={{ display: 'flex', flexDirection: 'row', gap: 4, }}>
            <Iconify fontSize={13} py={"auto"} icon={"eva:checkmark-circle-2-fill"} color={"success.main"} />
            <Typography fontSize={13} color={"success.main"}>{t('dashboard.global.active')}</Typography>
          </div>
        }
      </Stack>

    );

  const renderText = (
    <ListItemText

      primary={
        <div style={{ display: 'flex', justifyContent: "space-between", }}>
          {folder?.name}
        </div>
      }
      secondary={

        <Stack direction={{ xs: 'column', sm: 'row' }} sx={{ width: '100%', alignItems: { xs: 'flex-start', sm: 'center' }, justifyContent: 'center', gap: 1, }}>
          <Typography
            variant="body2"
            fontSize={12}
            color="text.disabled"
            sx={{

              minWidth: 0,
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              // flex: 'auto',
              textAlign: 'flex-start',
            }}
          >
            {activeUsers?.length + ' ' + (activeUsers?.length === 1 ? t('user') : t('dashboard.admin.users'))}
          </Typography>

          <Typography
            variant="body2"
            fontSize={12}
            color="text.disabled"
            sx={{

              minWidth: 0,
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              flex: 2,
              textAlign: 'flex-start',
            }}
          >
            {/* {folder?.invitations_len + ' ' + t('dashboard.account.pending_invitations')} */}
            {pendingInvitations.length + ' ' + t('dashboard.account.pending_invitations')}
          </Typography>
        </Stack>
      }
      primaryTypographyProps={{
        noWrap: true,
        typography: 'subtitle1',
      }}
      secondaryTypographyProps={{
        mt: 0.5,
        component: 'span',
        alignItems: 'center',
        typography: 'caption',
        color: 'text.disabled',
        display: 'inline-flex',
      }}
    />
  );

  const renderAvatar = (
    <AvatarGroup
      max={3}
      sx={{
        [`& .${avatarGroupClasses.avatar}`]: {
          width: 24,
          height: 24,
          '&:first-of-type': {
            fontSize: 12,
          },
        },
      }}
    >
      {folder.shared?.map((person: any) => (
        <Avatar key={person.id} alt={person.name} src={person.avatarUrl} />
      ))}
    </AvatarGroup>
  );

  const handleTextFolder = (user: any) => {
    if (user?.toDelete) {
      setPendingInvitations((prev: any) => prev.filter((item: any) => item.email !== user.email))
      setActiveUsers((prev: any) => prev.filter((item: any) => item.email !== user.email))
      onInvitation?.({ email: user.email, id: user.id, workspaceId: folder.id, status: 'PENDING', toDelete: true })
    } else if (!user?.toDelete && user?.status === 'PENDING') {
      setPendingInvitations((prev: any) => [...prev, user])
      onInvitation?.({ email: user.email, id: user.id, workspaceId: folder.id, status: 'PENDING', toDelete: false, firstname: user.firstname, lastname: user.lastname })
    }
  }

  return (
    <>
      <Stack
        onMouseEnter={checkbox.onTrue}
        onMouseLeave={checkbox.onFalse}
        component={Paper}
        variant="outlined"
        spacing={1}
        alignItems="flex-start"
        sx={{
          p: 2,
          maxWidth: 333,
          borderRadius: 2,
          bgcolor: 'unset',
          cursor: 'pointer',
          position: 'relative',
          ...((checkbox.value || selected) && {
            bgcolor: 'background.paper',
            boxShadow: (theme) => theme.customShadows.z20,
          }),
          ...sx,
        }}
        {...other}
      >
        <Box onMouseEnter={checkbox.onTrue} onMouseLeave={checkbox.onFalse}>
          {renderIcon}
        </Box>
        {renderText}

        {(user?.data?.role === "admin" || user?.data?.role === "brand manager") &&
          <>
            {renderAction}
          </>
        }

        {!!folder?.shared?.length && renderAvatar}
      </Stack >

      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="right-top"
        sx={{ width: "auto" }}
      >
        <MenuItem
          onClick={() => {
            popover.onClose();
            router.replace(paths.workspaces.workspace(folder?.id))
          }}
        >
          <Iconify icon="mdi:eye" />
          {t('dashboard.global.view_users')}
        </MenuItem>

        <MenuItem
          onClick={() => {
            popover.onClose();
            details.onTrue();
          }}
        >
          <Iconify icon="mdi:eye" />
          {t('dashboard.global.view_details')}
        </MenuItem>

        <MenuItem
          onClick={() => {
            popover.onClose();
            // handleEdit(); // Appeler handleEdit ici
          }}
        >
          <Iconify icon="solar:pen-bold" />
          {t('dashboard.global.edit')}
        </MenuItem>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem
          onClick={() => {
            confirm.onTrue();
            popover.onClose();
          }}
          sx={{ color: 'error.main' }}
        >
          <Iconify icon="solar:trash-bin-trash-bold" />
          {t('dashboard.global.delete')}
        </MenuItem>
      </CustomPopover>

      <FileManagerFileDetails
        key={folder.id}
        item={folder}
        favorited={favorite.value}
        onFavorite={favorite.onToggle}
        onCopyLink={handleCopy}
        open={details.value}
        onClose={details.onFalse}
        onDelete={() => {

          onDelete();
        }}
        bqTables={bqTables}
        onUpdate={onUpdate}
        onInvitation={handleTextFolder}
      />

      <FileManagerShareDialog
        open={share.value}
        shared={folder.shared}
        inviteEmail={inviteEmail}
        onChangeInvite={handleChangeInvite}
        onCopyLink={handleCopy}
        onClose={() => {
          share.onFalse();
          setInviteEmail('');
        }}
      />

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title={t('dashboard.global.delete')}
        content={t('dashboard.global.are_you_sure_you_want_to_delete')}
        action={
          <Button variant="contained" color="error" onClick={onDelete}>
            {t('dashboard.global.delete')}
          </Button>
        }
      />
    </>
  );
}