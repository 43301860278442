import Chart, { useChart } from 'src/components/chart';
import { useTheme } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import { fPercent } from 'src/utils/format-number';
import Box from '@mui/material/Box';
import { useCurrentWorkspace } from 'src/context/reducers/app-settings';

type Props = {
  series: {
    name: string;
    data: number[];
  }[];
  labels: any;
};

export default function ChartColumnStacked({ series, labels }: Props) {
  const theme = useTheme();
  const filterStore = useSelector((state: any) => state.filters);
  const workspace = useCurrentWorkspace()
  enum ColorSchema {
    PRIMARY = 'primary',
    SECONDARY = 'secondary',
    TERTIARY = 'tertiary',
    ERROR = 'error',
    WARNING = 'warning',
    INFO = 'info',
    SUCCESS = 'success'
  }

  const formatCurrency = (number: any, options: any) => {
    const lang = localStorage.getItem('i18nextLng');
    const currencySymbol = '$';

    let formattedNumber;
    if (number >= 1000000) {
      formattedNumber = (number / 1000000).toFixed(1) + 'M';
    } else if (number >= 10000) {
      formattedNumber = (number / 1000).toFixed(1) + 'K';
    } else {
      formattedNumber = number.toLocaleString(lang, options);
    }

    if (lang === 'fr') {
      formattedNumber = formattedNumber.replace('.', ',');
    }

    return currencySymbol + formattedNumber;
  };

  const chartOptions = useChart({
    chart: {
      stacked: true,
      zoom: {
        enabled: true,
      },
    },
    series: series,
    labels: labels || [],
    legend: {
      itemMargin: {
        vertical: 8,
      },
      markers: {
        customHTML: function () {
          return '<div style="width: 16px; height: 16px; border-radius: 50%; background-color: currentcolor;"></div>';
        },
      },
      position: 'top',
      offsetY: 20,
    },

    fill: {
      colors: ["#065e49", theme.palette[ColorSchema.ERROR].light]
    },
    colors: ["#065e49", theme.palette[ColorSchema.ERROR].light],
    plotOptions: {
      bar: { barHeight: '80%', columnWidth: '40%', borderRadius: 0 },

    },
    stroke: {
      width: 0, // Désactiver les bordures des barres
      show: false,
    },
    xaxis: {
      // labels: {
      //   formatter: filterStore?.timestep === "date"
      //     ? (val) => {
      //       const date = new Date(val);
      //       const year = date.getUTCFullYear();
      //       const month = String(date.getUTCMonth() + 1).padStart(2, '0');
      //       const day = String(date.getUTCDate()).padStart(2, '0');
      //       return `${year}-${month}-${day}`;
      //     }
      //     : undefined,
      // },
    },
    tooltip: {

      shared: true,
      intersect: false,
      y: {
        formatter: (val: number) => {
          return `${(formatCurrency(val, { minimumFractionDigits: 1, maximumFractionDigits: 1 }))}`;
        },
      },
    },
    yaxis: [
      {
        labels: { formatter(value) { return formatCurrency(value, { minimumFractionDigits: 1, maximumFractionDigits: 1 }) } }
      },
    ],
    annotations: {
      xaxis: workspace?.name === "Demo" ?  [
    {
      x: filterStore?.timestep === 'date' ? '10/01/2023' : filterStore?.timestep === 'year_week' ? '09/18/2023' : '',
      x2: filterStore?.timestep === 'date' ? '10/16/2023' : filterStore?.timestep === 'year_week' ? '10/02/2023' : '',
      fillColor: '#ffe5e9',
      
    },
        {
      x:  filterStore?.timestep === 'date' ? '10/08/2023' : filterStore?.timestep === 'year_week' ? '09/25/2023' : '',
      fillColor: '#ffe5e9',
      borderColor: 'transparent',
      label: {
            orientation: "horizontal",
            borderColor: "#FF4560",
            style: {
              color: "#fff",
              background: "#FF4560",
              fontSize:'16px',
            },
            text: "Before Cross Brand"
          }
    },
         {
          // in a datetime series, the x value should be a timestamp, just like it is generated below
          x:filterStore?.timestep === 'date' ? '10/16/2023' : filterStore?.timestep === 'year_week' ? '10/02/2023' : '',
          strokeDashArray: 0,
          borderColor: "#775DD0",
          label: {
            borderColor: "#775DD0",
            style: {
              color: "#fff",
              background: "#775DD0",
              fontSize:'14px',
            },
            text: "Cross Brand Deployment"
          }
        },
        {
      x: filterStore?.timestep === 'date' ? '10/16/2023' : filterStore?.timestep === 'year_week' ? '10/02/2023' : '',
      x2: filterStore?.timestep === 'date' ? '10/31/2023' : filterStore?.timestep === 'year_week' ? '10/23/2023' : '',
      fillColor: '#B3F7CA',
    },
     {
      x: filterStore?.timestep === 'date' ? '10/23/2023' : filterStore?.timestep === 'year_week' ? '10/09/2023' : '',
      borderColor: 'transparent',
      label: {
            orientation: "horizontal",
            borderColor: "#00E396",
            style: {
              color: "#fff",
              background: "#00E396",
              fontSize:'16px',
            },
            text: "After Cross Brand"
          }
    }
  ] : []
    },
  });

  return (
    <Box>
      <Chart dir="ltr" type="bar" series={series} options={chartOptions} height={420} />
    </Box>
  );
}
