// components
import { useTheme } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import Chart, { useChart } from 'src/components/chart';
import { useCurrentWorkspace } from 'src/context/reducers/app-settings';

// ----------------------------------------------------------------------

type Props = {
  series: {
    name: string;
    data: number[];
  }[];
  labels: string[]; // Ajout de la prop labels
};

export default function ChartColumnStackedPercent({ series, labels }: Props) {
  const theme = useTheme();
  const filterStore = useSelector((state: any) => state.filters);
  const workspace = useCurrentWorkspace()

  enum ColorSchema {
    PRIMARY = 'primary',
    SECONDARY = 'secondary',
    TERTIARY = 'tertiary',
    ERROR = 'error',
    WARNING = 'warning',
    INFO = 'info',
    SUCCESS = 'success'
  }

  const chartOptions = useChart({
    chart: {
      stacked: true,
      type: 'bar',
      stackType: '100%',
    },
    series: series,
    fill: {
      colors: series?.length === 3
        ? [theme.palette[ColorSchema.SUCCESS].light, theme.palette[ColorSchema.TERTIARY].light, '#b7b7b7']
        : series?.length === 5 ? [theme.palette[ColorSchema.SUCCESS].light, "#1e88e5", "#64b5f6", '#b7dcfb', '#b7b7b7'] : ["#118d57", "#1e88e5", "#64b5f6", "#E1E1E1"],
    },
    colors: series?.length === 3
      ? [theme.palette[ColorSchema.SUCCESS].light, theme.palette[ColorSchema.TERTIARY].light, '#b7b7b7']
      : series?.length === 5 ? [theme.palette[ColorSchema.SUCCESS].light, "#1e88e5", "#64b5f6", '#b7dcfb', '#b7b7b7'] : ["#118d57", "#1e88e5", "#64b5f6", "#E1E1E1"],
    labels: labels || [],
    legend: {
      itemMargin: {
        vertical: 8,
      },
      markers: {
        customHTML: function () {
          return '<div style="width: 16px; height: 16px; border-radius: 50%; background-color: currentcolor;"></div>';
        },
      },
      position: 'top',
      // offsetY: 20,
    },
    plotOptions: {
      bar: {
        barHeight: '80%',
        columnWidth: '70%', // Réduire légèrement la largeur des colonnes
        borderRadius: 0,
        distributed: false,
        dataLabels: {
          position: 'top',
        },
      },
    },
    stroke: {
      width: 0, // Désactiver les bordures des barres
      show: false,
    },
    xaxis: {
      // labels: {
      //   formatter: filterStore?.timestep === "date"
      //     ? (val) => {
      //       const date = new Date(val);
      //       const year = date.getUTCFullYear();
      //       const month = String(date.getUTCMonth() + 1).padStart(2, '0');
      //       const day = String(date.getUTCDate()).padStart(2, '0');
      //       return `${year}-${month}-${day}`;
      //     }
      //     : undefined,
      // },
    },
    yaxis: {
      labels: {
        formatter: (val: number) => {
          return `${val} %`;
        }
      }
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (val: number, { dataPointIndex, w }) => {
          const total = w.globals.stackedSeriesTotals[dataPointIndex];
          const percentage = total ? ((val / total) * 100).toFixed(2) : 0;
          return `${val.toFixed(0)} (${percentage}%)`;
        },
      },
    },
    annotations:{
      xaxis:   workspace?.name === "Demo" ?  [
    {
      x: filterStore?.timestep === 'date' ? '10/01/2023' : filterStore?.timestep === 'year_week' ? '09/18/2023' : '10/16/2023',
      x2: filterStore?.timestep === 'date' ? '10/16/2023' : filterStore?.timestep === 'year_week' ? '10/02/2023' : '05/22/2023',
      fillColor: '#ffe5e9',
      
    },
        {
      x: filterStore?.timestep === 'date' ? '10/08/2023' : filterStore?.timestep === 'year_week' ? '09/25/2023': '05/11/2023',
      fillColor: '#ffe5e9',
      borderColor: 'transparent',
      label: {
            orientation: "horizontal",
            borderColor: "#FF4560",
            style: {
              color: "#fff",
              background: "#FF4560",
              fontSize:'16px',
            },
            text: "Before Cross Brand"
          }
    },
         {
          // in a datetime series, the x value should be a timestamp, just like it is generated below
          x: filterStore?.timestep === 'date' ? '10/16/2023' : filterStore?.timestep === 'year_week' ? '10/02/2023' : '05/22/2023',
          strokeDashArray: 0,
          borderColor: "#775DD0",
          label: {
            borderColor: "#775DD0",
            style: {
              color: "#fff",
              background: "#775DD0",
              fontSize:'14px',
            },
            text: "Cross Brand Deployment"
          }
        },
        {
      x: filterStore?.timestep === 'date' ? '10/16/2023' : filterStore?.timestep === 'year_week' ? '10/02/2023' : '05/22/2023',
      x2:filterStore?.timestep === 'date' ? '10/31/2023' : filterStore?.timestep === 'year_week' ? '10/23/2023' : '05/16/2023',
      fillColor: '#B3F7CA',
    },
     {
      x: filterStore?.timestep === 'date' ? '10/23/2023' : filterStore?.timestep === 'year_week' ? '10/16/2023' : '05/30/2023',
      borderColor: 'transparent',
      label: {
            orientation: "horizontal",
            borderColor: "#00E396",
            style: {
              color: "#fff",
              background: "#00E396",
              fontSize:'16px',
            },
            text: "After Cross Brand"
          }
    }
  ] : []
    },
  });

  return <Chart dir="ltr" type="bar" series={series} options={chartOptions} height={350} />;
}
